/** When your routing table is too long, you can split it into small modules **/

import Layout from "@/layout";

const adminRouter = [
  {
    path: "/statistics",
    component: Layout,
    redirect: "/statistics/basic",
    name: "Statistics",
    meta: {
      title: "社区埋点",
      icon: "iconfont icon-topic",
    },
    children: [
      {
        name: "StatisticsBasic",
        path: "statistics/basic",
        component: () => import("@/views/pages/statistics/index"),
        meta: {
          title: "业务数据",
          icon: "iconfont icon-topic",
        },
      },
      {
        name: "StatisticsTrack",
        path: "statistics/track",
        component: () => import("@/views/pages/statistics/track"),
        meta: {
          title: "统计埋点",
          icon: "iconfont icon-audit",
        },
      },
      {
        name: "StatisticsView",
        path: "statistics/view",
        component: () => import("@/views/pages/statistics/view"),
        meta: {
          title: "浏览量统计",
          icon: "iconfont icon-audit",
        },
      },
    ],
  },

  {
    path: "/user",
    component: Layout,
    redirect: "/user",
    children: [
      {
        name: "User",
        path: "",
        component: () => import("@/views/pages/user/index"),
        meta: {
          title: "用户管理",
          icon: "iconfont icon-username",
        },
      },
    ],
  },
  {
    path: "/content",
    component: Layout,
    redirect: "/content/topic",
    name: "content",
    meta: {
      title: "内容管理",
      icon: "iconfont icon-topic",
    },
    children: [
      {
        name: "Topic",
        path: "topic",
        component: () => import("@/views/pages/topic/index"),
        meta: {
          title: "帖子管理",
          icon: "iconfont icon-topic",
        },
      },
      {
        name: "TopicReview",
        path: "topic/review",
        component: () => import("@/views/pages/topic/review"),
        meta: {
          title: "帖子审核",
          icon: "iconfont icon-audit",
        },
      },
      {
        name: "Article",
        path: "article",
        component: () => import("@/views/pages/article/index"),
        meta: {
          title: "文章管理",
          icon: "iconfont icon-article",
        },
      },
      {
        name: "ArticleReview",
        path: "article/review",
        component: () => import("@/views/pages/article/review"),
        meta: {
          title: "文章审核",
          icon: "iconfont icon-audit",
        },
      },
      {
        name: "ForbiddenWord",
        path: "forbidden-word",
        component: () => import("@/views/pages/forbidden-word/index"),
        meta: {
          title: "违禁词",
          icon: "iconfont icon-forbidden",
        },
      },
      {
        name: "Class",
        path: "class",
        component: () => import("@/views/pages/class/index"),
        meta: {
          title: "课程管理",
          icon: "iconfont icon-category",
        },
      },
      {
        path: "class/detail/:id?", // 不设name不会加入顶部导航
        component: () => import("@/views/pages/class/detail"),
        hidden: true,
        meta: {
          title: "课程详情",
        },
      },
      {
        path: "class/statis/:id",
        component: () => import("@/views/pages/class/statis"),
        hidden: true,
        meta: {
          title: "统计信息",
        },
      },

      {
        name: "Question",
        path: "question",
        component: () => import("@/views/pages/question/index"),
        meta: {
          title: "题库管理",
          icon: "iconfont icon-category",
        },
      },
      {
        path: "question/detail/:id", // 不设name不会加入顶部导航
        component: () => import("@/views/pages/question/detail"),
        hidden: true,
        meta: {
          title: "试卷详情",
        },
      },
      {
        name: "Certificate",
        path: "certificate",
        component: () => import("@/views/pages/certificate/index"),
        meta: {
          title: "证书管理",
          icon: "iconfont icon-category",
        },
      },
      {
        path: "certificate/record/:id", // 不设name不会加入顶部导航
        component: () => import("@/views/pages/certificate/record"),
        hidden: true,
        meta: {
          title: "获取记录",
        },
      },
      {
        name: "Activity",
        path: "activity",
        component: () => import("@/views/pages/activity/index"),
        meta: {
          title: "活动管理",
          icon: "iconfont icon-category",
        },
      },
      {
        name: "SidebarConfig",
        path: "sidebarConfig",
        component: () => import("@/views/pages/sidebarConfig/index"),
        meta: {
          title: "展示配置",
          icon: "iconfont icon-category",
        },
      },
      {
        name: "FaqConfig",
        path: "faqConfig",
        component: () => import("@/views/pages/faqConfig/index"),
        meta: {
          title: "faq默认配置",
          icon: "iconfont icon-category",
        },
      },
    ],
  },
  {
    path: "/category",
    component: Layout,
    redirect: "/category/nodes",
    name: "cocategoryntent",
    meta: {
      title: "分类管理",
      icon: "iconfont icon-tags",
    },
    children: [
      {
        name: "Nodes",
        path: "nodes",
        component: () => import("@/views/pages/topic/nodes"),
        meta: {
          title: "节点",
          icon: "iconfont icon-tag",
        },
      },
      {
        name: "Tags",
        path: "tags",
        component: () => import("@/views/pages/tags/index"),
        meta: {
          title: "标签",
          icon: "iconfont icon-tags",
        },
      },
      {
        name: "ClassSections",
        path: "classSection",
        component: () => import("@/views/pages/category/section/index"),
        meta: {
          title: "课程板块",
          icon: "iconfont icon-tag",
        },
      },
    ],
  },
  {
    path: "/banner",
    component: Layout,
    redirect: "/banner",
    children: [
      {
        name: "Banner",
        path: "",
        component: () => import("@/views/pages/banner/index"),
        meta: {
          title: "Banner管理",
          icon: "iconfont icon-category",
        },
      },
    ],
  },
  {
    path: "/links",
    component: Layout,
    redirect: "/links",
    children: [
      {
        name: "Links",
        path: "",
        component: () => import("@/views/pages/links/index"),
        meta: {
          title: "更多应用",
          icon: "iconfont icon-link",
        },
      },
    ],
  },
  {
    path: "/settings",
    component: Layout,
    redirect: "/settings",
    children: [
      {
        path: "",
        component: () => import("@/views/pages/settings/index"),
        name: "settings",
        meta: {
          title: "系统设置",
          icon: "iconfont icon-setting",
        },
      },
    ],
  },
];
export default adminRouter;
